import './Job.scss';

const Job = ({ content }) => {
  return (
    <div className='Job-Row'>
      <div className='Job-Title'>{content.JobTitle}</div>
      <div className='Job-Dates'>{content.Dates}</div>
      <div className='Job-Description'>{content.Description}</div>
      {!!content.Responsibilities && <div className='Job-Responsibilities-Header'>
        Responsibilities
      </div>
      }
      <div className='Job-Responsibilities'>
        {!!content.Responsibilities && content.Responsibilities?.map((responsibility, key) => {
          return (
            <div key={key} className='Job-Responsibility-Row'>
              <div className='Bullet-Point'>&#42;</div>
              <div key={key} className='Job-Responsibility'>
                {responsibility}
              </div>
            </div>
          )
        })}
      </div>
      {!!content.Projects && <div className='Projects-Header'>Projects
      </div>}
      {!!content.Projects &&
        content.Projects.map((project, key) => {
          return (
            <div key={key} className='Project'>
              <div className='Project_Name'>{project.Name}</div>
              {project.Technologies && (
                <div className='Project_Technologies'>
                  {project.Technologies}
                </div>
              )}
              <div className='Project_Description'>{project.Description}</div>
            </div>
          );
        })}
    </div>
  );
};

export default Job;
