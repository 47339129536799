import './Header.scss';
import profile from './profile.svg';
import content from './resume-content.json';

const header = content.header
const contacts = header?.contacts

const Header = () => {
  return (
    <div className='Header'>
      <div className='Header-Left'>
        <img src={profile} alt="Hey look, it's me" />
        <div className='Header-NameTag'>
          <div className='Header-Name'>David Singleton</div>
          <div className='Header-Title'>
            Software Developer and Cloud Architect
          </div>
        </div>
      </div>
      <div className='Header-Links'>
        {contacts && contacts?.map(({ title, link, text }, key) =>
          <div key={key} className='Header-Contact'>{title}:<a href={link}>{text}</a></div>
        )}
      </div>
    </div>
  );
};

export default Header;
