import './Body.scss';
import Job from './Job';
import content from './resume-content.json';

const { WorkExperience: workExperience } = content?.rows[1];

const WorkExperience = () => {
  return (
    <div className='Body-Row'>
      <div className='Body-Left'>
        Work Experience
      </div>
      <div className='Body-Right'>
        {workExperience.map((job, key) => {
          return <Job key={key} content={job} />;
        })}
      </div>
    </div>
  )
}

export default WorkExperience;