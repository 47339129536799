import './Item.scss';

const Item = ({ content }) => {
  return (
    <div className="Item-Row">
      <div className="Item-Title">
        {content.Title}
      </div>
      <div className="Item-DateReceived">
        {content.DateReceived}
      </div>
      <div className="Item-Descriptor">
        {content.Description}
      </div>
    </div>
  );
};

export default Item;
