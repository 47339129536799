import content from './resume-content.json';
import WorkExperience from './WorkExperience';
import Item from './Item';
import './Body.scss';

const description = content?.rows[0];
const { CertificationsAndSchool } = content?.rows[2];

const Body = () => {
  return (
    <div className='Body'>
      <div className='Body-Row'>
        <div className='Body-Left'>
          Description
        </div>
        <div className='Body-Right'>
          {description.Description}
        </div>
      </div>
      <div className='Body-Break' />
      <WorkExperience />
      <div className='Body-Break' />
      <div className='Body-Row'>
        <div className='Body-Left'>
          Certifications and School
        </div>
        <div className='Body-Right'>
          {CertificationsAndSchool.map((item, key) => {
            return <Item key={key} content={item} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default Body;
