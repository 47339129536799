import Body from './Body';
import Header from './Header';
import './App.scss';

function App() {
  return (
    <div className='App'>
      <div className='Page'>
        {/* <div className='Top'></div> */}
        <Header className='Header' />
        <div className='Break' />
        <Body />
        {/* <div className='Bottom'></div> */}
      </div>
    </div>
  );
}

export default App;
